/* tslint:disable */
/* eslint-disable */
/**
 * API Documentation
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ApiAssetsSignedRecentGet200ResponseAssetsInner
 */
export interface ApiAssetsSignedRecentGet200ResponseAssetsInner {
    /**
     * Person who signed the asset
     * @type {string}
     * @memberof ApiAssetsSignedRecentGet200ResponseAssetsInner
     */
    signer?: string;
    /**
     * Unique browsable URL for the asset
     * @type {string}
     * @memberof ApiAssetsSignedRecentGet200ResponseAssetsInner
     */
    browsableUrl?: string;
}

/**
 * Check if a given object implements the ApiAssetsSignedRecentGet200ResponseAssetsInner interface.
 */
export function instanceOfApiAssetsSignedRecentGet200ResponseAssetsInner(value: object): value is ApiAssetsSignedRecentGet200ResponseAssetsInner {
    return true;
}

export function ApiAssetsSignedRecentGet200ResponseAssetsInnerFromJSON(json: any): ApiAssetsSignedRecentGet200ResponseAssetsInner {
    return ApiAssetsSignedRecentGet200ResponseAssetsInnerFromJSONTyped(json, false);
}

export function ApiAssetsSignedRecentGet200ResponseAssetsInnerFromJSONTyped(json: any, ignoreDiscriminator: boolean): ApiAssetsSignedRecentGet200ResponseAssetsInner {
    if (json == null) {
        return json;
    }
    return {
        
        'signer': json['signer'] == null ? undefined : json['signer'],
        'browsableUrl': json['browsable_url'] == null ? undefined : json['browsable_url'],
    };
}

  export function ApiAssetsSignedRecentGet200ResponseAssetsInnerToJSON(json: any): ApiAssetsSignedRecentGet200ResponseAssetsInner {
      return ApiAssetsSignedRecentGet200ResponseAssetsInnerToJSONTyped(json, false);
  }

  export function ApiAssetsSignedRecentGet200ResponseAssetsInnerToJSONTyped(value?: ApiAssetsSignedRecentGet200ResponseAssetsInner | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'signer': value['signer'],
        'browsable_url': value['browsableUrl'],
    };
}

