import React, { useEffect, useState } from 'react';
import AlphabetNav from './AlphabetNav';
import PeopleList from './PeopleList';
import ApiService from '../services/ApiService';
import PersonDetailsModal from './PersonDetailsModal';
import Search from './Search';
import { Person } from 'common-lib/generated-client';
import { useParams, useNavigate } from 'react-router-dom';
import { DIRECTORY_PATH } from 'components-lib';
import { useRollbar } from '@rollbar/react';
import AssetList from './AssetList';

const RECENT_ASSETS_FILTER_ONLY_FOLLOWING = 'recent_assets_filter_only_following';
const RECENT_ASSETS_FILTER_SINCE_LAST_SEEN = 'recent_assets_filter_since_last_seen';

const Directory: React.FC = () => {
  const rollbar = useRollbar();
  const [filteredPeople, setFilteredPeople] = useState<Person[]>([]);
  const [searchQuery, setSearchQuery] = useState<string>('');
  const [activeLetters, setActiveLetters] = useState<string[]>([]);
  const [selectedPerson, setSelectedPerson] = useState<Person | null>(null);
  const [installationId] = useState<string | undefined>(
    localStorage.getItem('installation_id') ?? undefined
  );
  const [onlyFollowing, setOnlyFollowing] = useState(
    !!localStorage.getItem(RECENT_ASSETS_FILTER_ONLY_FOLLOWING)
  );
  const [sinceLastSeen, setSinceLastSeen] = useState(
    !!localStorage.getItem(RECENT_ASSETS_FILTER_SINCE_LAST_SEEN)
  );

  const { code } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    const fetchActiveLetters = async () => {
      try {
        const response = await ApiService.firstLetters();
        setActiveLetters(response.firstLetters ?? []);
      } catch (error) {
        rollbar.error('Error fetching active letters:', error as Error);
      }
    };
    fetchActiveLetters();
  }, [rollbar]);

  useEffect(() => {
    if (code) {
      const fetchPerson = async () => {
        const person = await ApiService.getPerson(code);
        if (person) {
          // If there's a vanityTag and it's different from the URL code, redirect
          if (person.vanityTag && code !== person.vanityTag) {
            navigate(`${DIRECTORY_PATH}/${person.vanityTag}`);
            return;
          }
          setSelectedPerson(person);
        }
      };
      fetchPerson().catch((error) => rollbar.error('Error fetching person details:', error));
    } else {
      // Clear selected person if URL code is removed
      setSelectedPerson(null);
    }
  }, [code, navigate, rollbar]);

  const handleLetterClick = (letter: string) => {
    setSearchQuery(letter);
    fetchPeople(letter);
  };

  const handleSearchChange = (query: string) => {
    setSearchQuery(query);
    if (query.length > 0) {
      fetchPeople(query);
    } else {
      setFilteredPeople([]);
    }
  };

  const handleViewDetails = (person: Person) => {
    navigate(`${DIRECTORY_PATH}/${person.vanityTag ?? person.authorCode}`);
  };

  const handleCloseModal = () => {
    setSelectedPerson(null);
    navigate(DIRECTORY_PATH);
  };

  const fetchPeople = async (query: string) => {
    try {
      const result = await ApiService.search(query);
      setFilteredPeople(result.people || []);
    } catch (error) {
      console.error('Error fetching search results:', error);
    }
  };

  const updateOnlyFollowing = (checked: boolean) => {
    setOnlyFollowing(checked);
    if (checked) {
      localStorage.setItem(RECENT_ASSETS_FILTER_ONLY_FOLLOWING, 'true');
    } else {
      localStorage.removeItem(RECENT_ASSETS_FILTER_ONLY_FOLLOWING);
    }
  };

  const updateSinceLastSeen = (checked: boolean) => {
    setSinceLastSeen(checked);
    if (checked) {
      localStorage.setItem(RECENT_ASSETS_FILTER_SINCE_LAST_SEEN, 'true');
    } else {
      localStorage.removeItem(RECENT_ASSETS_FILTER_SINCE_LAST_SEEN);
    }
  };

  return (
    <>
      {/* If a person is selected, show the modal */}
      {selectedPerson && <PersonDetailsModal person={selectedPerson} onClose={handleCloseModal} />}

      {/* If no person is selected, show the main directory UI */}
      {!selectedPerson && (
        <div className="directory-root">
          {/*
            FIXED SECOND HEADER (under the main site header).
              1) Search row (right-aligned)
              2) AlphabetNav row (centered)
              3) "Recently signed assets" (centered) -- only if no search query
          */}
          <div className="directory-header-container">
            <div className="directory-header-inner">
              {/* 1) SEARCH (RIGHT-ALIGNED) */}
              <div className="directory-search-row">
                <Search query={searchQuery} onSearchChange={handleSearchChange} />
              </div>

              {/* 2) ALPHABET NAV (CENTERED) */}
              <div className="directory-alphabet-row">
                <AlphabetNav activeLetters={activeLetters} onLetterClick={handleLetterClick} />
              </div>

              {/* 3) "RECENTLY SIGNED ASSETS" ROW */}
              {!searchQuery && (
                <>
                  <h2 className="directory-asset-header-row">Recently signed assets</h2>
                  {installationId && (
                    <div className="asset-filters">
                      <div className="filter-switch">
                        <span className="filter-label">Only following</span>
                        <label className="switch">
                          <input
                            type="checkbox"
                            checked={onlyFollowing}
                            onChange={(e) => updateOnlyFollowing(e.target.checked)}
                          />
                          <span className="slider round"></span>
                        </label>
                      </div>
                      <div className="filter-switch">
                        <span className="filter-label">Since your last visit to the directory</span>
                        <label className="switch">
                          <input
                            type="checkbox"
                            checked={sinceLastSeen}
                            onChange={(e) => updateSinceLastSeen(e.target.checked)}
                          />
                          <span className="slider round"></span>
                        </label>
                      </div>
                    </div>
                  )}
                </>
              )}
            </div>
          </div>
          <div className="directory-content-container">
            {searchQuery ? (
              <div className="people-list-wrapper">
                <PeopleList people={filteredPeople} onViewDetails={handleViewDetails} />
              </div>
            ) : (
              <div className="asset-list-wrapper">
                <AssetList
                  installationId={installationId}
                  onlyFollowing={onlyFollowing}
                  sinceLastSeen={sinceLastSeen}
                />
              </div>
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default Directory;
