import { DefaultApi } from 'common-lib';
import {
  Configuration,
  ApiPeopleFirstLettersGet200Response,
  Person,
} from 'common-lib/generated-client';

class ApiService {
  static defaultApi = new DefaultApi(
    new Configuration({ basePath: process.env.REACT_APP_API_BASE_URL })
  );
  static async search(searchQuery: string): Promise<{ people?: Person[] }> {
    return await this.defaultApi.apiPeopleGet({ search: searchQuery });
  }
  static async firstLetters(): Promise<ApiPeopleFirstLettersGet200Response> {
    return await this.defaultApi.apiPeopleFirstLettersGet();
  }
  static getPersonImageURL(person: Person): string {
    return `${process.env.REACT_APP_API_BASE_URL}/api/people/${person.authorCode}/image`;
  }
  static async getAssets(authorCode: string) {
    return await this.defaultApi.apiPeopleCodeAssetsGet({ code: authorCode });
  }
  static async getSocialMediaAccounts(authorCode: string) {
    return await this.defaultApi.apiPeopleCodeSocialMediaGet({ code: authorCode });
  }
  static async getPerson(
    authorCode: string
  ): Promise<(Person & { followersCount?: number }) | undefined> {
    return (await this.defaultApi.apiPeopleCodeGet({ code: authorCode })).person;
  }
  static async getRecentlySignedAssets(filter: {
    installationId?: string;
    onlyFollowing?: boolean;
    sinceLastSeen?: boolean;
  }): Promise<{
    assets?: { signer?: string; browsableUrl?: string }[];
  }> {
    return await this.defaultApi.apiAssetsSignedRecentGet(filter);
  }
}

export default ApiService;
