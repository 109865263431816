import React, { useState, useEffect } from 'react';
import ApiService from '../services/ApiService';

interface AssetListProps {
  installationId?: string;
  onlyFollowing?: boolean;
  sinceLastSeen?: boolean;
}
const AssetList: React.FC<AssetListProps> = ({ installationId, onlyFollowing, sinceLastSeen }) => {
  const [assets, setAssets] = useState<{ signer: string; browsableUrl: string }[] | null>(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchAssets = async () => {
      try {
        const response = await ApiService.getRecentlySignedAssets({
          installationId,
          onlyFollowing,
          sinceLastSeen,
        });
        function hasBrowsableUrl(a: {
          signer?: string;
          browsableUrl?: string;
        }): a is { signer: string; browsableUrl: string } {
          return typeof a.browsableUrl === 'string' && a.signer !== undefined;
        }
        const loadedAssets = response.assets?.filter(hasBrowsableUrl) ?? [];
        setAssets(loadedAssets);
      } catch (err) {
        setError(err.message || 'Failed to fetch assets');
      }
    };

    fetchAssets();
  }, [installationId, onlyFollowing, sinceLastSeen]);

  if (error) {
    return <div className="error">Error: {error}</div>;
  }

  if (assets === null) {
    return <div className="loading">Loading assets...</div>;
  }

  if (!assets.length) {
    return <div className="empty">No signed assets found</div>;
  }

  return (
    <div className="asset-list">
      <table className="asset-table">
        <tbody>
          {assets.map(
            (asset, index) =>
              asset.browsableUrl && (
                <tr key={index} className="asset-row">
                  <td className="signer-column">{asset.signer}</td>
                  <td className="link-column">
                    <a
                      href={asset.browsableUrl}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="asset-link"
                    >
                      {asset.browsableUrl}
                    </a>
                  </td>
                </tr>
              )
          )}
        </tbody>
      </table>
    </div>
  );
};

export default AssetList;
