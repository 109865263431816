/* tslint:disable */
/* eslint-disable */
/**
 * API Documentation
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { ApiAssetsSignedRecentGet200ResponseAssetsInner } from './ApiAssetsSignedRecentGet200ResponseAssetsInner';
import {
    ApiAssetsSignedRecentGet200ResponseAssetsInnerFromJSON,
    ApiAssetsSignedRecentGet200ResponseAssetsInnerFromJSONTyped,
    ApiAssetsSignedRecentGet200ResponseAssetsInnerToJSON,
    ApiAssetsSignedRecentGet200ResponseAssetsInnerToJSONTyped,
} from './ApiAssetsSignedRecentGet200ResponseAssetsInner';

/**
 * 
 * @export
 * @interface ApiAssetsSignedRecentGet200Response
 */
export interface ApiAssetsSignedRecentGet200Response {
    /**
     * Array of unique signer/URL pairs in reverse chronological order
     * @type {Array<ApiAssetsSignedRecentGet200ResponseAssetsInner>}
     * @memberof ApiAssetsSignedRecentGet200Response
     */
    assets?: Array<ApiAssetsSignedRecentGet200ResponseAssetsInner>;
}

/**
 * Check if a given object implements the ApiAssetsSignedRecentGet200Response interface.
 */
export function instanceOfApiAssetsSignedRecentGet200Response(value: object): value is ApiAssetsSignedRecentGet200Response {
    return true;
}

export function ApiAssetsSignedRecentGet200ResponseFromJSON(json: any): ApiAssetsSignedRecentGet200Response {
    return ApiAssetsSignedRecentGet200ResponseFromJSONTyped(json, false);
}

export function ApiAssetsSignedRecentGet200ResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): ApiAssetsSignedRecentGet200Response {
    if (json == null) {
        return json;
    }
    return {
        
        'assets': json['assets'] == null ? undefined : ((json['assets'] as Array<any>).map(ApiAssetsSignedRecentGet200ResponseAssetsInnerFromJSON)),
    };
}

  export function ApiAssetsSignedRecentGet200ResponseToJSON(json: any): ApiAssetsSignedRecentGet200Response {
      return ApiAssetsSignedRecentGet200ResponseToJSONTyped(json, false);
  }

  export function ApiAssetsSignedRecentGet200ResponseToJSONTyped(value?: ApiAssetsSignedRecentGet200Response | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'assets': value['assets'] == null ? undefined : ((value['assets'] as Array<any>).map(ApiAssetsSignedRecentGet200ResponseAssetsInnerToJSON)),
    };
}

